import React, {useContext, lazy, Suspense, useEffect} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {
    LOGIN,
    DASHBOARD,
    SETTINGS,
    RIDES_SETTINGS,
    COUNTER_SETTINGS,
    ACCESS_POINT_SETTINGS,
    ACCESS_POINT,
    PACKAGE_SETTINGS,
    USER_SETTINGS,
    ADD_PACKAGE_SETTINGS,
    WRISTBAND_SETTINGS,
    OPERATION_INVENTORY_TRANSFER,
    COUNTER_OPERATION,
    OPERATION_INVENTORY,
    COUNTER,
    SUPPLIER,
    TICKET_COUNTER,
    PACKAGE_DETAILS,
    RIDES_CHECKER,
    PRODUCT_SETTINGS,
    EMPLOYEE_COUNTER,
    TICKET_COUNTER_OPERATION,
    OPERATION_BANK,
    OPERATION_EXPENSE,
    OPERATION_SUPPLIER,
    TICKET_COUNTER_INVOICES,
    TICKET_COUNTER_TOPUP,
    INVOICE_PRINT,
    LOCKER,
    COUNTER_RENT_RETURN,
    COUNTER_INVOICES,
    COUNTER_WRISTBAND_RETURN,
    TRANSACTIONS,
    TRANSACTIONS_REPORT,
    ADD_TRANSACTIONS,
    OPERATION_TRANSACTION_REPORT,
    OPERATION_ADD_TRANSACTION,
    TC_OPERATION_TRANSACTION_REPORT,
    TC_OPERATION_ADD_TRANSACTION,
    TC_OPERATION_EXPENSE,
    TC_OPERATION_BANK,
    CUSTOMER_WRISTBAND,
    DEVICE_SETTINGS,
    OPERATION_DAILY_REPORT,
    VIEW_CUSTOMER_STATEMENT,
    TC_OPERATION_DAILY_REPORT,
    TRANSACTIONS_PARK_REPORT,
    CUSTOMER_STATEMENT_PRINT,
    COUNTER_WRISTBAND_FINE
} from "settings/constants";
import AuthProvider, {AuthContext} from "context/auth";
import {InLineLoader} from "components/InlineLoader/InlineLoader";

const RideSetting = lazy(() => import("./containers/Settings/Ride/Ride"));
const ProductSetting = lazy(() => import("./containers/Settings/Product/Product"));
const CounterOperationCounters = lazy(() => import("./containers/CounterOperations/Counters"));
const CounterOperations = lazy(() => import("./containers/CounterOperations/CounterOperations"));
const TicketCounterOperationCounters = lazy(() => import("./containers/TicketCounterOperation/TicketCounters"));
const TicketCounterOperations = lazy(() => import("./containers/TicketCounterOperation/TicketCounterOperations"));
const AccessPointSetting = lazy(() => import("./containers/Settings/AccessPoint/AccessPoint"));
const AccessPoints = lazy(() => import("./containers/AccessPoints/AccessPoints"));
const AccessPointOperation = lazy(() => import("./containers/AccessPoints/AccessPoint"));
const SalesInvoices = lazy(() => import("./containers/Sales/SalesInvoices"));
const TicketCounter = lazy(() => import("./containers/TicketCounters/TicketCounter"));
const TicketCounters = lazy(() => import("./containers/TicketCounters/TicketCounters"));
const PackageDetails = lazy(() => import("./containers/TicketCounters/PackageDetails"));
const PackageSetting = lazy(() => import("./containers/Settings/Package/Package"));
const AddPackageSetting = lazy(() => import("./containers/Settings/Package/AddPackage"));
const UserSetting = lazy(() => import("./containers/Settings/User/Users"));
const Counters = lazy(() => import("./containers/Counters/Counters"));
const WristBandSettings = lazy(() => import("./containers/Settings/WristBand/WristBand"));
const WristBandReturn = lazy(() => import("./containers/Counters/WristBandReturn/WristBandReturn"));
const WristBandFine = lazy(() => import("./containers/TicketCounters/WristBandFine"));
const RentReturn = lazy(() => import("./containers/Counters/RentProductReturn"));
const EmployeeAssignAccessPoint = lazy(() => import("./containers/Settings/CounterEmployee/CounterEmployees"));

const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Settings = lazy(() => import("./containers/Settings/Settings"));
const DeviceSetting = lazy(() => import("./containers/Settings/Device/Device"));
const CounterSetting = lazy(() => import("./containers/Settings/Counter/Counter"));
const OperationAddTransaction = lazy(() => import("./containers/CounterOperations/Transaction/AddTransaction"));
const OperationTransactionReport = lazy(() => import("./containers/CounterOperations/Transaction/TransactionReport"));
const OperationDailyReport = lazy(() => import("./containers/CounterOperations/Report/CounterReport"));
const TcOperationDailyReport = lazy(() => import('./containers/TicketCounterOperation/Report/TicketCounterReport'));
const TcOperationTransactionReport = lazy(() => import("./containers/TicketCounterOperation/TransactionReport"));
const Supplier = lazy(() => import("./containers/CounterOperations/Supplier/Supplier"));
const Bank = lazy(() => import("./containers/CounterOperations/Bank/Bank"));
const Expense = lazy(() => import("./containers/CounterOperations/Expense/Expense"));
const Inventory = lazy(() => import("./containers/CounterOperations/Inventory/Inventory"));
const InventoryTransfer = lazy(() => import("./containers/CounterOperations/Inventory/InventoryTransfer"));
const RidesChecker = lazy(() => import("./containers/RidesChecker/RideChecker"));
const CounterPOS = lazy(() => import("./containers/Counters/CounterPOS"));
const InvoicePrint = lazy(() => import("./containers/InvoicePrint/InvoicePrint"));
const AddTransactions = lazy(() => import("./containers/Transactions/AddTransactions"));
const TransactionsParkReport = lazy(() => import("./containers/Transactions/ParkReport"));
const TransactionsReport = lazy(() => import("./containers/Transactions/TransactionsReport"));
const Transactions = lazy(() => import("./containers/Transactions/Transactions"));
const CustomerStatement = lazy(() => import("./containers/Customers/CustomerStatement"));
const CustomerStatementPrint = lazy(() => import("./containers/InvoicePrint/CustomerStatementPrint"));
const TopUp = lazy(() => import("./containers/TopUp/TopUp"));
const Locker = lazy(() => import("./containers/Locker/Locker"));
const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));

const Customers = lazy(() => import("./containers/Customers/Customers"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

// function PrivateRoute({ children, ...rest }) {
//   const { isAuthenticated } = useContext(AuthContext);

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isAuthenticated ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }
export function PrivateRoute({children, allowed = [], ...rest}) {
    const {isAuthenticated, checkToken, showRoute} = useContext(AuthContext);
    const show = showRoute(allowed);
    const focusHandler = () => {
        checkToken();
    };
    useEffect(() => {
        // subscribe event
        focusHandler();
        window.addEventListener("focus", focusHandler, false);
        return () => {
            // unsubscribe event
            window.removeEventListener("focus", focusHandler, false);
        };
    }, []);
    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated && show ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

const SuperAdmin = ["super_admin"];
const Admin = ["super_admin", "park_admin", "hotel_admin"];

const Routes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InLineLoader/>}>
                <Switch>
                    <Route path={LOGIN}>
                        <Login/>
                    </Route>

                    <PrivateRoute exact={true} path={DASHBOARD}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Dashboard/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={RIDES_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <RideSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={DEVICE_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <DeviceSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <CounterSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={ACCESS_POINT_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <AccessPointSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={EMPLOYEE_COUNTER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <EmployeeAssignAccessPoint/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={ADD_PACKAGE_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <AddPackageSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={PACKAGE_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <PackageSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={USER_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <UserSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={PRODUCT_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <ProductSetting/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={WRISTBAND_SETTINGS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <WristBandSettings/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={SETTINGS} allowed={SuperAdmin}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Settings/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={ACCESS_POINT + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <AccessPointOperation/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={ACCESS_POINT}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <AccessPoints/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={TICKET_COUNTER_INVOICES + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <SalesInvoices/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TICKET_COUNTER + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TicketCounter/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TICKET_COUNTER_TOPUP + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TopUp/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TICKET_COUNTER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TicketCounters/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={PACKAGE_DETAILS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <PackageDetails/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={COUNTER_INVOICES + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <SalesInvoices/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_WRISTBAND_FINE + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <WristBandFine/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_WRISTBAND_RETURN + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <WristBandReturn/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_RENT_RETURN + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <RentReturn/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <CounterPOS/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Counters/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={TC_OPERATION_DAILY_REPORT + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TcOperationDailyReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TC_OPERATION_TRANSACTION_REPORT + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TcOperationTransactionReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TC_OPERATION_ADD_TRANSACTION + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <OperationAddTransaction/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TC_OPERATION_EXPENSE + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Expense/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TC_OPERATION_BANK + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Bank/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TICKET_COUNTER_OPERATION + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TicketCounterOperations/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={TICKET_COUNTER_OPERATION}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TicketCounterOperationCounters/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={OPERATION_DAILY_REPORT + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <OperationDailyReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_TRANSACTION_REPORT + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <OperationTransactionReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_ADD_TRANSACTION + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <OperationAddTransaction/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_SUPPLIER + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Supplier/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_EXPENSE + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Expense/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_BANK + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Bank/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_INVENTORY_TRANSFER + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <InventoryTransfer/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={OPERATION_INVENTORY + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Inventory/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_OPERATION + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <CounterOperations/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={COUNTER_OPERATION}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <CounterOperationCounters/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute path={SUPPLIER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Supplier/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={RIDES_CHECKER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <RidesChecker/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute path={INVOICE_PRINT + "/:id"}>
                        <Suspense fallback={<InLineLoader/>}>
                            <InvoicePrint/>
                        </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path={CUSTOMER_STATEMENT_PRINT + "/:qr"}>
                        <Suspense fallback={<InLineLoader/>}>
                            <CustomerStatementPrint/>
                        </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path={LOCKER}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Locker/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path={ADD_TRANSACTIONS}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <AddTransactions/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute exact path={TRANSACTIONS_REPORT}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TransactionsReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute exact path={TRANSACTIONS_PARK_REPORT}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <TransactionsParkReport/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>
                    <PrivateRoute exact path={TRANSACTIONS} allowed={Admin}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Transactions/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path={VIEW_CUSTOMER_STATEMENT}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <CustomerStatement/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <PrivateRoute exact path={CUSTOMER_WRISTBAND + "/:id"}>
                        <AdminLayout>
                            <Suspense fallback={<InLineLoader/>}>
                                <Customers/>
                            </Suspense>
                        </AdminLayout>
                    </PrivateRoute>

                    <Route component={NotFound}/>
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default Routes;
