import React, { useState } from 'react';
import { useLazyQuery, gql} from '@apollo/client';
import { Auth } from "../settings/common";
import {useHistory} from 'react-router-dom';


type AuthProps = {
  isAuthenticated: boolean;
  loading: boolean;
  loginError: boolean;
  authenticate: Function;
  checkToken: Function;
  showRoute: Function;
  signout: Function;
};

const AUTH_QUERY = gql`query auth($username: String!, $password: String!){userQuery{
    authorize(userName: $username, password: $password){
      expiresIn
      token
      refreshToken
      issuer
      roles
    }
  }
}`;

const isValidToken = () => {
  let isAuth = false;
  const auth_data = Auth.get();
  if (auth_data) {
    const expiration = new Date(auth_data.expiresIn);
    isAuth = expiration >= new Date();
  }
  return isAuth;
}

export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
  const [loginError, setLoginError] = useState(false);
  const history = useHistory();

  const [requestAuth, { loading }] = useLazyQuery(AUTH_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      Auth.set(data);
      setIsAuthenticated(true);
      setLoginError(false);
    }, onError: (e) => {
      setLoginError(true)
    }
  });


  function authenticate({ username, password }, cb) {
    requestAuth({
      variables: {
        username: username, password: password
      },
    });
    setTimeout(cb, 100);
  }

  function checkToken() {
    setIsAuthenticated(isValidToken());
  }

  function showRoute(allowedRoles){
    if (!allowedRoles || allowedRoles.length === 0) return true;
    const token = Auth.get();
    return (token && token.roles) ? allowedRoles.some(r => token.roles.includes(r)) : false;
  }

  function signout(cb) {
    Auth.remove();
    history.replace('', null);
    setIsAuthenticated(false);
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        loading,
        loginError,
        checkToken,
        showRoute,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
